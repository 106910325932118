var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-container"},[[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"tile":"","small":""},on:{"click":function($event){return _vm.$router.push({
              name: 'Contracts',
            })}}},[_vm._v("Zpět")])],1)],1),_c('h3',{staticClass:"mt-5 mb-8 col-secodary-2"},[_vm._v("Smlouva")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Název smlouvy *","disabled":['SUPERUSER', 'PROJECT_MANAGER'].includes(_vm.user.role)
              ? false
              : true},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-textarea',{attrs:{"label":"Poznámka","auto-grow":"","disabled":['SUPERUSER', 'PROJECT_MANAGER'].includes(_vm.user.role)
              ? false
              : true},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}})],1)],1),(_vm.data.project_manager != null)?_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('span',{staticClass:"col-secondary-3",staticStyle:{"font-size":"14px"}},[_vm._v("Projektový manager: "+_vm._s(_vm.data.project_manager.first_name)+" "+_vm._s(_vm.data.project_manager.last_name))])])],1):_vm._e(),_c('input',{ref:"documentFile",staticClass:"input--file",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":""},on:{"input":function($event){return _vm.handleInputFile($event.target.files)}}}),_c('div',{staticClass:"d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"},[_vm._l((_vm.data.documents),function(document,documentIdx){return _c('div',{key:document.id},[_c('div',{staticClass:"document-added mr-md-6 cursor-pointer",on:{"click":function($event){return _vm.downloadDoc(document.documentId)}}},[_c('div',{staticClass:"document-document-mini",style:(_vm.correctIcon(document))}),_c('div',{staticClass:"document-extension"},[_vm._v(" "+_vm._s(document.extension ? document.extension.toUpperCase() : '')+" ")]),(_vm.user.role == 'PROJECT_MANAGER' || _vm.user.role == 'SUPERUSER')?_c('div',{staticClass:"document-close"},[_c('button',{on:{"click":function($event){$event.stopPropagation();(_vm.focusedId = document.documentId),
                  (_vm.focusedIdx = documentIdx),
                  (_vm.dialog = true)}}},[_c('img',{attrs:{"src":require('@/assets/zavrit.svg'),"alt":""}})])]):_vm._e()]),_c('v-text-field',{staticStyle:{"width":"150px"},attrs:{"disabled":_vm.user.role != 'PROJECT_MANAGER' && _vm.user.role != 'SUPERUSER',"label":"Název souboru *"},model:{value:(document.name),callback:function ($$v) {_vm.$set(document, "name", $$v)},expression:"document.name"}})],1)}),(_vm.user.role != 'END_USER' && _vm.user.role != 'COMPANY_CUSTOMER')?_c('button',{staticClass:"document-input-add",on:{"click":function($event){return _vm.handleClick('documentFile')}}},[_c('div',{staticClass:"document-input-add-plus"},[_vm._v("+")]),_c('div',{staticClass:"document-input-add-text"},[_vm._v("Vložit dokument")])]):_vm._e()],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}})],1),_c('v-row',{staticClass:"mt-8"},[_c('v-col',{staticClass:"text-right"},[(_vm.user.role === 'SUPERUSER')?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"error","small":"","tile":""},on:{"click":function($event){_vm.deleteDialog = true}}},[_vm._v("Odstranit")]):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"small":"","tile":"","color":"secondary"},on:{"click":function($event){return _vm.$router.push({
              name: 'Contracts',
            })}}},[_vm._v("Zrušit")]),(_vm.user.role == 'PROJECT_MANAGER' || _vm.user.role == 'SUPERUSER')?_c('v-btn',{attrs:{"small":"","tile":"","color":"primary"},on:{"click":_vm.update}},[_vm._v("Uložit")]):_vm._e()],1)],1)],_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{staticClass:"rounded-0",attrs:{"max-width":450,"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Odstranit dokument")]),_c('v-card-subtitle',[_vm._v("Opravdu si přejete odstranit dokument ?")]),_c('v-card-text',[_c('v-row'),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","small":"","tile":"","width":"100%"},on:{"click":_vm.handleDeleteFile}},[_vm._v("Odstranit")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"small":"","text":"","width":"100%"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Zrušit")])],1)],1)],1),_c('v-card-actions')],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{staticClass:"rounded-0",attrs:{"max-width":450,"persistent":""},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Odstranit smlouvu")]),_c('v-card-subtitle',[_vm._v("Opravdu si přejete odstranit smlouvu ?")]),_c('v-card-text',[_c('v-row'),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"error","small":"","tile":"","width":"100%"},on:{"click":_vm.handleDeleteContract}},[_vm._v("Odstranit")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{attrs:{"small":"","text":"","width":"100%"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Zrušit")])],1)],1)],1),_c('v-card-actions')],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }