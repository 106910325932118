<template>
  <div class="admin-container">
    <template>
      <v-row>
        <v-col cols="12" md="12">
          <v-btn
            tile
            small
            @click="
              $router.push({
                name: 'Contracts',
              })
            "
            >Zpět</v-btn
          >
        </v-col>
      </v-row>
      <h3 class="mt-5 mb-8 col-secodary-2">Smlouva</h3>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="data.name"
            label="Název smlouvy *"
            :disabled="
              ['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
                ? false
                : true
            "
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-textarea
            v-model="data.description"
            label="Poznámka"
            auto-grow
            :disabled="
              ['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)
                ? false
                : true
            "
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row class="mt-4" v-if="data.project_manager != null">
        <v-col>
          <span style="font-size: 14px" class="col-secondary-3"
            >Projektový manager: {{ data.project_manager.first_name }}
            {{ data.project_manager.last_name }}</span
          >
        </v-col>
      </v-row>

      <input
        style="display: none"
        ref="documentFile"
        type="file"
        class="input--file"
        multiple
        @input="handleInputFile($event.target.files)"
      />
      <div
        class="d-flex flex-column flex-md-row align-center align-md-start doc-wrapper"
      >
        <div
          v-for="(document, documentIdx) in data.documents"
          :key="document.id"
        >
          <div
            class="document-added mr-md-6 cursor-pointer"
            @click="downloadDoc(document.documentId)"
          >
            <div
              class="document-document-mini"
              :style="correctIcon(document)"
            ></div>
            <div class="document-extension">
              {{ document.extension ? document.extension.toUpperCase() : '' }}
            </div>
            <!-- <div class="document-text">
              Dokument <br />
              <strong
                ><span v-if="document.editor != null"
                  >Vloženo zákazníkem
                </span></strong
              >
            </div> -->
            <div
              class="document-close"
              v-if="user.role == 'PROJECT_MANAGER' || user.role == 'SUPERUSER'"
            >
              <button
                @click.stop="
                  (focusedId = document.documentId),
                    (focusedIdx = documentIdx),
                    (dialog = true)
                "
              >
                <img :src="require('@/assets/zavrit.svg')" alt="" />
              </button>
            </div>
          </div>
          <v-text-field
            style="width: 150px"
            v-model="document.name"
            :disabled="
              user.role != 'PROJECT_MANAGER' && user.role != 'SUPERUSER'
            "
            label="Název souboru *"
          ></v-text-field>
        </div>

        <button
          class="document-input-add"
          @click="handleClick('documentFile')"
          v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
        >
          <div class="document-input-add-plus">+</div>
          <div class="document-input-add-text">Vložit dokument</div>
        </button>
      </div>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn
            v-if="user.role === 'SUPERUSER'"
            color="error"
            class="mr-4"
            small
            tile
            @click="deleteDialog = true"
            >Odstranit</v-btn
          >
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="
              $router.push({
                name: 'Contracts',
              })
            "
            >Zrušit</v-btn
          >
          <v-btn
            small
            tile
            color="primary"
            @click="update"
            v-if="user.role == 'PROJECT_MANAGER' || user.role == 'SUPERUSER'"
            >Uložit</v-btn
          >
        </v-col>
      </v-row>
    </template>

    <v-row justify="center">
      <v-dialog v-model="dialog" :max-width="450" persistent class="rounded-0">
        <v-card>
          <v-card-title class="headline">Odstranit dokument</v-card-title>
          <v-card-subtitle
            >Opravdu si přejete odstranit dokument ?</v-card-subtitle
          >
          <v-card-text>
            <v-row> </v-row>
            <v-row class="mt-6">
              <v-col class="text-center">
                <v-btn
                  color="primary"
                  small
                  tile
                  width="100%"
                  @click="handleDeleteFile"
                  >Odstranit</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn small text @click="dialog = false" width="100%"
                  >Zrušit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-model="deleteDialog"
        :max-width="450"
        persistent
        class="rounded-0"
      >
        <v-card>
          <v-card-title class="headline">Odstranit smlouvu</v-card-title>
          <v-card-subtitle
            >Opravdu si přejete odstranit smlouvu ?</v-card-subtitle
          >
          <v-card-text>
            <v-row> </v-row>
            <v-row class="mt-6">
              <v-col class="text-center">
                <v-btn
                  color="error"
                  small
                  tile
                  width="100%"
                  @click="handleDeleteContract"
                  >Odstranit</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center">
                <v-btn small text @click="deleteDialog = false" width="100%"
                  >Zrušit</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import bus from '../plugins/bus';
import authHandler from '../api/authHandler';
import permissionDenied from '../api/permissionDenied';
import errorHandler from '../api/errorHandler';
import documentService from '../api/documentService';
import contractService from '../api/contractService';
const IMAGES_EXT = ['jpg', 'jpeg', 'svg', 'png'];
const PDF = 'pdf';
const ZIP = 'zip';
const PPT = 'ppt';
const XLS = 'xls';

export default {
  data() {
    return {
      data: {},
      dialog: false,
      focusedId: null,
      focusedIdx: null,
      documents: [],
      deleteDialog: false,
    };
  },
  async created() {
    // permissionDenied(this.user, this.$router, "SUPERUSER", "PROJECT_MANAGER");
    bus.$emit('processing');
    await this.show();
    bus.$emit('processing', false);
  },
  methods: {
    correctIcon(doc) {
      let icon = 'document.svg';
      if (IMAGES_EXT.includes(doc.extension)) icon = 'jpg.svg';
      if (PDF == doc.extension) icon = 'pdf.svg';
      if (doc.extension.includes(ZIP)) icon = 'zip.svg';
      if (doc.extension.includes(PPT)) icon = 'ppt.svg';
      if (doc.extension.includes(XLS)) icon = 'xls.svg';
      return {
        backgroundImage: `url(${require('@/assets/' + icon)})`,
      };
    },
    // async handleDeleteFile(documentId, documentIdx) {
    //   try {
    //     bus.$emit("processing");
    //     await documentService._delete(documentId);
    //     this.data.documents.splice(documentIdx, 1);
    //     bus.$emit("processing", false);
    //   } catch (error) {
    //     bus.$emit("processing", false);
    //     console.error(error);
    //     errorHandler(error);
    //   }
    // },
    async handleDeleteContract() {
      try {
        bus.$emit('processing');
        await contractService.destroy(this.$route.params.id);
        this.$router.push({ name: 'Contracts' });
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async downloadDoc(id) {
      try {
        bus.$emit('processing');
        await documentService.show(id);
        bus.$emit('processing', false);
      } catch (error) {
        bus.$emit('processing', false);
        errorHandler(error);
      }
    },
    async handleDeleteFile() {
      try {
        bus.$emit('processing');
        await documentService._delete(this.focusedId);
        this.data.documents.splice(this.focusedIdx, 1);
        bus.$emit('processing', false);
        this.dialog = false;
        this.focusedId = null;
        this.focusedIdx = null;
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    handleClick(ref) {
      this.$refs[ref].click();
    },
    async handleInputFile(f) {
      try {
        console.debug(f);
        bus.$emit('processing');
        for (let fidx = 0; fidx < f.length; fidx++) {
          const { data } = await documentService.store(f[fidx]);
          if (['END_USER', 'COMPANY_CUSTOMER'].includes(this.user.role)) {
            await contractService.storeDocuments(this.$route.params.id, {
              document_id: data.id,
              name: data.client_name,
              extension: data.extension,
            });
            await this.show();
          } else {
            this.data.documents.push({
              documentId: data.id,
              name: data.client_name,
              mimetype: data.mimetype,
              mime: data.mime,
              extension: data.extension,
            });
          }
        }
        this.dialog = false;
        this.focusedId = null;
        this.focusedIdx = null;
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: `Podařilo se nahrát dokumenty`,
          color: 'info',
        });
        this.$refs.documentFile.values = [];
      } catch (error) {
        bus.$emit('processing', false);
        console.error(error);
        errorHandler(error);
      }
    },
    async show() {
      try {
        const data = await contractService.show(this.$route.params.id);
        this.data = data;
        console.debug(data);
      } catch (error) {
        errorHandler(error);
      }
    },
    async update() {
      try {
        bus.$emit('processing');
        const response = await contractService.update(this.$route.params.id, {
          ...this.data,
        });
        console.debug('Response', response);
        bus.$emit('processing', false);
        bus.$emit('snackbar', {
          text: 'Uloženo',
          color: 'success',
        });
        this.$router.push({
          name: 'Contracts',
        });
      } catch (error) {
        console.error(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
};
</script>

<style></style>
